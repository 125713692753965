<template>
  <div>
    <!-- lead-boxes -->
    <div v-if="show">
      <div class="timeline col-md-12 text-right">
        <button
          type="button"
          v-bind:class="filterSearch.period == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.period == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.period == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Day</span>
        </button>
      </div>
      <div class="leads-boxes">
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0006 18.4854C14.6869 18.4854 18.486 14.6864 18.486 10C18.486 5.31364 14.6869 1.51459 10.0006 1.51459C5.31419 1.51459 1.51514 5.31364 1.51514 10C1.51514 14.6864 5.31419 18.4854 10.0006 18.4854Z"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.3872 7.58706L7.58722 7.61302L7.61318 12.413"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.4129 12.387L7.58691 7.61298"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style="margin-left: 5px">{{ campaignStats.requests }}</span>
          </h3>
          <p>Request</p>
        </a>
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.3332 10H14.9998L12.4998 17.5L7.49984 2.5L4.99984 10H1.6665"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style="margin-left: 5px">{{ campaignStats.bounces }}</span>
          </h3>
          <p>Bounces</p>
        </a>
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.3332 9.23336V10C18.3321 11.797 17.7503 13.5456 16.6743 14.9849C15.5983 16.4242 14.0859 17.4771 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8122C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0234 1.56633 11.2401 1.68457 9.44696C1.80281 7.65383 2.49751 5.94697 3.66507 4.58092C4.83263 3.21488 6.41049 2.26285 8.16333 1.86682C9.91617 1.47079 11.7501 1.65198 13.3915 2.38336"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3333 3.33331L10 11.675L7.5 9.17498"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span style="margin-left: 5px">{{ campaignStats.delivered }}</span>
          </h3>
          <p>Delivered</p>
        </a>
        <a
          :to="{ path: '/leads', query: { type: '3' } }"
          class="leads-box"
          :class="$route.query.type == 3 ? 'active' : ''"
        >
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.3332 9.66669H13.3332L11.6665 12.1667H8.33317L6.6665 9.66669H1.6665"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.6665 9.66667L4.5415 3.925C4.67948 3.64732 4.89219 3.41364 5.15571 3.25023C5.41922 3.08682 5.7231 3.00016 6.03317 3H13.9665C14.2766 3.00016 14.5805 3.08682 14.844 3.25023C15.1075 3.41364 15.3202 3.64732 15.4582 3.925L18.3332 9.66667V14.6667C18.3332 15.1087 18.1576 15.5326 17.845 15.8452C17.5325 16.1577 17.1085 16.3333 16.6665 16.3333H3.33317C2.89114 16.3333 2.46722 16.1577 2.15466 15.8452C1.8421 15.5326 1.6665 15.1087 1.6665 14.6667V9.66667Z"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style="margin-left: 5px">{{ campaignStats.opens }}</span>
          </h3>
          <p>Opens</p>
        </a>
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.6667 9.66669H14.6444M6 9.66669H2"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 6.5L18.6667 9.66667V14.6667C18.6667 15.1087 18.4911 15.5326 18.1785 15.8452C17.866 16.1577 17.442 16.3333 17 16.3333H3.66667C3.22464 16.3333 2.80072 16.1577 2.48816 15.8452C2.17559 15.5326 2 15.1087 2 14.6667V9.66667L4.875 3.925C5.01298 3.64732 5.22569 3.41364 5.4892 3.25023C5.75272 3.08682 6.0566 3.00016 6.36667 3H10.5"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.8333 1L10.5 9.34167L8 6.84167"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span style="margin-left: 5px">{{
              campaignStats.unique_opens
            }}</span>
          </h3>
          <p>Unique Opens</p>
        </a>
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M8.39167 16.6417L2.5 2.5L16.6417 8.39167L10.4833 10.4833L8.39167 16.6417Z"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.8335 10.8333L15.8335 15.8333"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style="margin-left: 5px"> {{ campaignStats.clicks }}</span>
          </h3>

          <p>Clicks</p>
        </a>
        <a class="leads-box">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewbox="0 0 20 20"
              fill="none"
            >
              <path
                d="M1.51681 15L8.57514 3.21665C8.7237 2.97174 8.93287 2.76925 9.18248 2.62872C9.43208 2.48819 9.71369 2.41437 10.0001 2.41437C10.2866 2.41437 10.5682 2.48819 10.8178 2.62872C11.0674 2.76925 11.2766 2.97174 11.4251 3.21665L18.4835 15C18.629 15.252 18.706 15.5377 18.7068 15.8288C18.7076 16.1198 18.6322 16.4059 18.4881 16.6588C18.344 16.9116 18.1362 17.1223 17.8854 17.2699C17.6346 17.4174 17.3495 17.4968 17.0585 17.5H2.94181C2.65081 17.4968 2.36571 17.4174 2.1149 17.2699C1.86408 17.1223 1.65627 16.9116 1.51216 16.6588C1.36805 16.4059 1.29265 16.1198 1.29346 15.8288C1.29428 15.5377 1.37128 15.252 1.51681 15Z"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 7.5V10.8333"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 14.1667H10.0083"
                stroke="#A2A8B8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style="margin-left: 5px">{{
              campaignStats.invalid_emails
            }}</span>
          </h3>
          <p>Invalid Emails</p>
        </a>
      </div>
      <!-- lead-boxes -->
      <div>
        <div class="sale-grap salegrap1">
          <div class="title-grap">
            <h4>Stats</h4>
          </div>
          <div class="graph-boxhome">
            <apexcharts
              v-if="graph"
              height="280"
              type="line"
              :options="leadsChartOptions"
              :series="leadsChartData"
            ></apexcharts>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loader" class="sale-grap salegrap1">
      <div class="title-grap"></div>
      <div class="graph-boxhome text-center">
        <img
          src="../../assets/img/Spinner.gif"
          class="img-fluid"
          style="width: 200px"
        />
      </div>
    </div>
    <div v-if="nofound" class="sale-grap salegrap1">
      <div class="title-grap"></div>
      <div class="graph-boxhome text-center">
        <p style="font-weight: 800px; margin-top: 60px">No Stats Available</p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    apexcharts: VueApexCharts,
    DateRangePicker,
  },
  data() {
    return {
      filterSearch: {
        by: [],
        from: {},
        to: {},
        period: "",
        agent: "",
        team: "",
        customDate: {},
        status: "",
      },
      campaignStats: {},
      show: false,
      loader: false,
      nofound: false,
      graph: false,
      leadsChartOptions: {
        stroke: {
          width: [2, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },

        fill: {
          opacity: [1, 1, 1],
          gradient: {
            inverseColors: false,
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        markers: {
          size: 0,
        },
        xaxis: {
          type: "text",
          labels: {
            show: true,
          },
        },
        yaxis: {
          min: 0,
        },
        colors: [
          "#793BE3",
          "#47CEFF",
          "#19BE93",
          "#FF470E",
          "#FFCB46",
          "#CE8329",
          "#BF6FFE",
        ],
        tooltip: {
          shared: true,
          //intersect: false,
          // y: {
          //     formatter: function(y) {
          //         if (typeof y !== "undefined") {
          //             return y.toFixed(0) + " points";
          //         }
          //         return y;

          //     }
          // }
        },
      },
      leadsChartData: [],
    };
  },
  methods: {
    updateData(val) {
      if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.filterSearch.datefrom = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.filterSearch.dateto = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );
        this.filterSearch.period = "custom";
      } else {
        this.filterSearch.period = val;
      }
      this.getCapaignStatsGraph();
    },
    getCapaignStatsGraph() {
      axios
        .get("/graphcampaignstats", {
          params: {
            id: this.$route.params.id,
            start_date: this.filterSearch.datefrom,
            end_date: this.filterSearch.dateto,
            duration: this.filterSearch.period,
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          (this.leadsChartOptions = {
            stroke: {
              width: [2, 2, 2],
              curve: "smooth",
            },
            plotOptions: {
              bar: {
                columnWidth: "30%",
              },
            },

            fill: {
              opacity: [1, 1, 1],
              gradient: {
                inverseColors: false,
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
              },
            },
            labels: [],
            markers: {
              size: 0,
            },
            xaxis: {
              type: "text",
              labels: {
                show: true,
              },
            },
            yaxis: {
              min: 0,
            },
            colors: [
              "#793BE3",
              "#47CEFF",
              "#19BE93",
              "#FF470E",
              "#FFCB46",
              "#CE8329",
              "#BF6FFE",
            ],
            tooltip: {
              shared: true,
              //intersect: false,
              // y: {
              //     formatter: function(y) {
              //         if (typeof y !== "undefined") {
              //             return y.toFixed(0) + " points";
              //         }
              //         return y;

              //     }
              // }
            },
          }),
            (this.graph = true);
          console.log(res.data.success.labels);
          this.campaignStats = res.data.success.allCounts;
          this.leadsChartOptions.labels = res.data.success.labels;
          this.leadsChartData = res.data.success.data;
          if (res.data.success.filter) {
            this.show = true;
            this.nofound = false;
            return false;
          } else {
            if (this.leadsChartData.length > 0) {
              this.show = true;
              this.nofound = false;
              this.loader = true;
            } else {
              this.loader = true;
              this.nofound = true;
              this.show = false;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // axios
      //   .get("graphcampaignstats/" + this.$route.params.id, {
      //     headers: {
      //       Authorization: "Bearer " + this.$storage.get("auth").token,
      //     },
      //   })
      //   .then((res) => {
      //     if (res) {
      //       this.graph = true;
      //       console.log(res.data.success.labels);
      //       this.campaignStats = res.data.success.allCounts;
      //       this.leadsChartOptions.labels = res.data.success.labels;
      //       this.leadsChartData = res.data.success.data;
      //       if (this.leadsChartData.length > 0) {
      //         this.show = true;
      //         this.nofound = false;
      //         this.loader = true;
      //       } else {
      //         this.loader = true;
      //         this.nofound = true;
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
  },
  created() {
    this.getCapaignStatsGraph();
  },
};
</script>
<style scoped>
a.leads-box {
  cursor: default !important;
}
a.leads-box:hover {
  float: left;

  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(49 81 161 / 8%);
  border-radius: 10px;
  transition: all 0.4s ease;
  margin-bottom: 25px;
  text-decoration: none;
}
a.leads-box:hover h3 {
  color: #3151a1;
  font-size: 18px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1.5;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
a.leads-box:hover .icons path {
  stroke: #3151a1;
}
a.leads-box:hover p {
  color: #3151a1;
}
</style>


